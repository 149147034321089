import { type SerializeFrom } from '@remix-run/node';
import {
	useAppLoaderData,
	type loader as appLoader,
} from '~/routes/app+/_layout.tsx';

function isUser(user: any): user is SerializeFrom<typeof appLoader>['user'] {
	return user && typeof user === 'object' && typeof user.id === 'string';
}

export function useOptionalUser() {
	const data = useAppLoaderData();
	if (!data || !data.user || !isUser(data.user)) {
		return undefined;
	}
	return data.user;
}

export function useUser() {
	const maybeUser = useOptionalUser();
	if (!maybeUser) {
		throw new Error('No user found in app loader');
	}
	return maybeUser;
}
